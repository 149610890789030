import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import { forwardRef, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import InputField from "~/components/form/input-field";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useCartProduct } from "~/hooks/use-cart";
import Logger from "~/services/logger/logger";
import {
  QuantitySelectorDesktopFields,
  quantitySelectorDesktopValidationSchema,
  QuantitySelectorMobileFields,
  quantitySelectorMobileValidationSchema,
} from "~/shared/product/quantity-selector-fields";
import { EcommerceTrackingItemModel } from "~/types/trackings.models";
import Constants from "~/utils/constants";

import Button from "./button";
import Dialog from "./dialog";
import Icon from "./icon";
import styles from "./quantity-selector.module.scss";
import dialogStyles from "./quantity-selector-dialog.module.scss";

type PropsQuantitySelector = {
  productID: number;
  lineID?: number;
  maxQuantity?: number;
  customClassNames?: string;
  disableRemove?: boolean;
  disableAdd?: boolean;
  disableChangeQuantity?: boolean;
  onChange?: (qty: number, prev: number) => void;
  actionContext?: string;
  trackingData?: EcommerceTrackingItemModel;
};

export default forwardRef<HTMLDivElement, PropsQuantitySelector>(function QuantitySelector(
  {
    productID,
    lineID,
    maxQuantity = Constants.PRODUCT_MAX_QUANTITY,
    customClassNames,
    disableRemove = false,
    disableAdd = false,
    disableChangeQuantity = false,
    actionContext = "",
    trackingData,
  }: PropsQuantitySelector,
  ref
) {
  const trackingItem = trackingData
    ? {
        context: actionContext,
        info: trackingData,
      }
    : undefined;

  const delayQuantityTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { quantityInCart, changeQuantity } = useCartProduct(productID!, lineID || undefined, trackingItem);
  const t = useTranslations();
  const breakpoint = useBreakpoint();
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const inputQuantityRef = useRef<HTMLInputElement | null>(null);
  const inputQuantityModalRef = useRef<HTMLInputElement | null>(null);

  const numericalMaxQuantity = useMemo(() => {
    if (typeof maxQuantity === "string" && !isNaN(parseInt(maxQuantity))) {
      return parseInt(maxQuantity);
    } else if (typeof maxQuantity === "number") {
      return maxQuantity;
    } else {
      return Constants.PRODUCT_MAX_QUANTITY;
    }
  }, [maxQuantity]);

  // initialize product quantity form
  const { register, handleSubmit, setValue, getValues, clearErrors, formState } = useForm<
    ReturnType<typeof useBreakpoint> extends "desktop" ? QuantitySelectorDesktopFields : QuantitySelectorMobileFields
  >({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: breakpoint === "desktop" ? true : false,
    resolver:
      breakpoint === "desktop"
        ? zodResolver(quantitySelectorDesktopValidationSchema())
        : zodResolver(
            quantitySelectorMobileValidationSchema({
              productMaxQuantity: numericalMaxQuantity,
            })
          ),
  });
  const {
    ref: inputRef,
    onChange: inputOnChange,
    onBlur: inputOnBlur,
    ...inputRegisterRest
  } = register("Quantity", { valueAsNumber: true });
  const { errors } = formState;

  // things to do when async request of quantity change goes ok
  useEffect(() => {
    if (changeQuantity.isSuccess) {
      if (breakpoint === "desktop") {
        inputQuantityRef.current?.blur();
      } else if (breakpoint === "mobile") {
        setIsDialogOpened(false);
      }
      changeQuantity.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeQuantity.isSuccess, breakpoint]);

  // in case of quantity mutation errors, print errors on console
  useEffect(() => {
    if (changeQuantity.error) {
      Logger.instance.error("Quantity selector errored > Change quantity", changeQuantity.error);
      changeQuantity.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeQuantity.error]);

  // In case of validation errors, cancel the debounce timer
  useEffect(() => {
    if (errors.Quantity) {
      if (delayQuantityTimeoutId.current) {
        clearTimeout(delayQuantityTimeoutId.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.Quantity]);

  // Update the value of quantity a soon as quantityInCart changes
  useEffect(() => {
    setValue("Quantity", quantityInCart ?? 0, { shouldValidate: true, shouldDirty: true });
  }, [quantityInCart, setValue]);

  // Clear the debounce timer when component unmounts
  useEffect(() => {
    return () => {
      if (delayQuantityTimeoutId.current) {
        clearTimeout(delayQuantityTimeoutId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickIncrease = (evt?: MouseEvent): void => {
    const nextQuantity = Math.floor(getValues("Quantity")) + 1;
    setValue("Quantity", Math.max(0, Math.min(nextQuantity, numericalMaxQuantity)), {
      shouldValidate: true,
      shouldDirty: true,
    });
    // Only on desktop - auto-submit quantity change at button pressure (with debounce)
    if (breakpoint === "desktop") {
      handleSubmit(onSubmit)();
    }
  };

  const handleClickDecrease = (evt?: MouseEvent): void => {
    const nextQuantity = Math.ceil(getValues("Quantity")) - 1;
    setValue("Quantity", Math.max(0, Math.min(nextQuantity, numericalMaxQuantity)), {
      shouldValidate: true,
      shouldDirty: true,
    });
    // Only on desktop - auto-submit quantity change at button pressure (with debounce)
    if (breakpoint === "desktop") {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit: SubmitHandler<
    ReturnType<typeof useBreakpoint> extends "desktop" ? QuantitySelectorDesktopFields : QuantitySelectorMobileFields
  > = (
    data: ReturnType<typeof useBreakpoint> extends "desktop"
      ? QuantitySelectorDesktopFields
      : QuantitySelectorMobileFields
  ) => {
    // avoid submitting the form if the quantity submitted is the same as the quantity already in cart
    if (data.Quantity === quantityInCart) {
      return;
    }
    // constrain the value between min and max (unfortunately we cannot use schema trnasofmrations)
    if (data.Quantity > numericalMaxQuantity) {
      setValue("Quantity", numericalMaxQuantity, { shouldValidate: true, shouldDirty: true });
    }
    // clear debounce timeout if not already elapsed...
    if (delayQuantityTimeoutId.current) {
      clearTimeout(delayQuantityTimeoutId.current);
    }
    // ...and override it with another debounce timeout starting right away
    delayQuantityTimeoutId.current = setTimeout(() => {
      changeQuantity.mutate({ ...data, maxQuantity: numericalMaxQuantity });
    }, Constants.DEFAULT_DEBOUNCE_TIME);
  };

  // Dialog callbacks
  const onDialogClose = () => {
    if (delayQuantityTimeoutId.current) {
      clearTimeout(delayQuantityTimeoutId.current);
    }
    // when closing the dialog, align the form input to the value in cart
    if (!changeQuantity.isLoading) {
      setValue("Quantity", quantityInCart ?? 0, { shouldValidate: true, shouldDirty: true });
    }
    // Close the dialog
    setIsDialogOpened(false);
  };

  return (
    <>
      <motion.div
        className={classNames(styles.quantitySelector, customClassNames)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        tabIndex={-1}
        ref={ref}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classNames(styles.quantityForm, changeQuantity.isLoading && styles.disabledQuantityForm)}
          noValidate
        >
          <button
            className={classNames("beacon-cart-mod-btn", styles.quantityButton)}
            type="button"
            disabled={disableRemove || changeQuantity.isLoading}
            onClick={(evt): void => {
              if (breakpoint === "desktop") {
                if (disableRemove !== true) {
                  handleClickDecrease();
                }
              } else if (breakpoint === "mobile") {
                setIsDialogOpened(true);
              }
            }}
            aria-label={t("a11y.quantityDecrease")}
          >
            <Icon name="minus" width={16} height={16} />
          </button>
          <input
            {...inputRegisterRest}
            ref={(element) => {
              inputRef(element);
              inputQuantityRef.current = element;
            }}
            aria-label={t("a11y.quantity")}
            type="number"
            className={styles.quantityInput}
            defaultValue={quantityInCart ?? 0}
            readOnly={
              !disableChangeQuantity
                ? breakpoint === "mobile"
                  ? true
                  : breakpoint === "desktop" && changeQuantity.isLoading
                  ? true
                  : false
                : false
            }
            onFocus={(evt): void => {
              if (breakpoint === "mobile") {
                setIsDialogOpened(true);
              }
            }}
            // onChange={(evt): void => {
            //   inputOnChange(evt);
            //   if (breakpoint === "desktop") {
            //     if (isNaN(getValues("Quantity"))) {
            //       setValue("Quantity", quantityInCart, { shouldValidate: true, shouldDirty: true });
            //     }
            //     if (getValues("Quantity") !== quantityInCart) {
            //       handleSubmit(onSubmit)();
            //     }
            //   }
            // }}
            onBlur={(evt): void => {
              inputOnBlur(evt);
              if (breakpoint === "desktop") {
                if (isNaN(getValues("Quantity"))) {
                  setValue("Quantity", quantityInCart, { shouldValidate: true, shouldDirty: true });
                }
                if (getValues("Quantity") !== quantityInCart) {
                  handleSubmit(onSubmit)();
                }
              }
            }}
          />
          <button
            className={classNames("beacon-cart-mod-btn", styles.quantityButton)}
            type="button"
            disabled={disableAdd || changeQuantity.isLoading}
            onClick={(evt): void => {
              if (breakpoint === "desktop") {
                if (disableAdd !== true) {
                  handleClickIncrease();
                }
              } else if (breakpoint === "mobile") {
                setIsDialogOpened(true);
              }
            }}
            aria-label={t("a11y.quantityIncrease")}
          >
            <Icon name="plus" width={16} height={16} />
          </button>
          <button
            type="submit"
            disabled={changeQuantity.isLoading}
            className={styles.submitButton}
            aria-label={t("generic.submit")}
          >
            {t("generic.submit")}
          </button>
        </form>
      </motion.div>
      {/* For a11y reasons, focusing on the field on mobile devices will open a dialog with the same form. Agreed with Kiko (Diego) and the design team on 2023/11/13 */}
      {breakpoint === "mobile" ? (
        <Dialog
          isOpen={isDialogOpened}
          title={
            <>
              {t.rich("generic.productQuantityModifyTitle", {
                CartQuantityIcon: (chunks) => <Icon name="cart-quantity" />,
              })}
            </>
          }
          onClose={onDialogClose}
          shouldReturnFocusAfterClose={false}
          customStyles={dialogStyles}
        >
          <p className={dialogStyles.description}>
            {t("generic.productQuantityModifyDescription", {
              maxProducts: numericalMaxQuantity,
            })}
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classNames(
              dialogStyles.quantityForm,
              changeQuantity.isLoading && dialogStyles.disabledQuantityForm
            )}
          >
            <div className={dialogStyles.quantityFormInputs}>
              <Button
                className={classNames("beacon-cart-mod-btn", dialogStyles.quantityButton)}
                type="button"
                disabled={disableRemove || changeQuantity.isLoading}
                onClick={(evt): void => {
                  if (disableRemove !== true) {
                    handleClickDecrease();
                  }
                }}
                aria-label={t("a11y.quantityDecrease")}
              >
                <Icon name="minus" width={16} height={16} />
              </Button>
              <InputField
                {...inputRegisterRest}
                ref={(element) => {
                  inputRef(element);
                  inputQuantityModalRef.current = element;
                }}
                label={t("a11y.quantity")}
                className={dialogStyles.quantityInput}
                customStyles={{
                  wrapperInputField: dialogStyles.wrapperInputField,
                  inputField: dialogStyles.inputField,
                }}
                aria-label={t("a11y.quantity")}
                type="number"
                defaultValue={quantityInCart ?? 0}
                readOnly={disableChangeQuantity || changeQuantity.isLoading}
                error={
                  errors.Quantity?.message
                    ? t(errors.Quantity?.message, {
                        fieldName: t("a11y.quantity"),
                        minValue: 0,
                        maxValue: numericalMaxQuantity,
                      })
                    : ""
                }
                onClearErrors={() => clearErrors("Quantity")}
                onBlur={(evt): void => {
                  inputOnBlur(evt);
                }}
              />
              <Button
                className={classNames("beacon-cart-mod-btn", dialogStyles.quantityButton)}
                type="button"
                disabled={disableAdd || changeQuantity.isLoading}
                onClick={(evt): void => {
                  if (disableAdd !== true) {
                    handleClickIncrease();
                  }
                }}
                aria-label={t("a11y.quantityIncrease")}
              >
                <Icon name="plus" width={16} height={16} />
              </Button>
            </div>
            <div className={dialogStyles.quantityFormSubmit}>
              <Button
                variant="secondary"
                type="reset"
                disabled={changeQuantity.isLoading}
                className={dialogStyles.resetButton}
                onClick={(evt): void => {
                  setValue("Quantity", quantityInCart ?? 0, { shouldValidate: true, shouldDirty: true });
                }}
              >
                {t("generic.cancel")}
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={changeQuantity.isLoading}
                loading={changeQuantity.isLoading}
                className={dialogStyles.submitButton}
              >
                {t("generic.add_to_bag")}
              </Button>
            </div>
          </form>
        </Dialog>
      ) : null}
    </>
  );
});

import { z } from "zod";

import Constants from "~/utils/constants";

type quantitySelectorMobileValidationSchemaProps = {
  productMaxQuantity?: number;
};

export const quantitySelectorMobileValidationSchema = ({
  productMaxQuantity = Constants.PRODUCT_MAX_QUANTITY,
}: quantitySelectorMobileValidationSchemaProps) =>
  z
    .object({
      Quantity: z
        .number({ invalid_type_error: "error.invalidField", required_error: "error.fieldRequired" })
        .nonnegative({ message: "error.valueTooLow" })
        .int({ message: "error.valueMustBeInteger" })
        .max(productMaxQuantity, { message: "error.valueTooHigh" })
        .default(0),
    })
    .required();

export type QuantitySelectorMobileFields = z.infer<ReturnType<typeof quantitySelectorMobileValidationSchema>>;

export type ChangeQuantityMobileProps = QuantitySelectorMobileFields & {
  maxQuantity: number;
};

export const quantitySelectorDesktopValidationSchema = () =>
  z.object({
    Quantity: z
      .number({ invalid_type_error: "error.invalidField" })
      .nonnegative({ message: "error.valueTooLow" })
      .int({ message: "error.valueMustBeInteger" })
      .default(0),
  });

export type QuantitySelectorDesktopFields = z.infer<ReturnType<typeof quantitySelectorDesktopValidationSchema>>;

export type ChangeQuantityDesktopProps = QuantitySelectorDesktopFields & {
  maxQuantity: number;
};
